export const settings = {
    groupByYear: true,
    showDayNames: true
};

export const days = [
    {
        date: "10/05/2024",
        description: "Brugdag"
    },
    {
        date: "20/05/2024",
        description: "Pinkstermaandag"
    },
    {
        date: "1/11/2024",
        description: "Allerheiligen"
    },
    {
        date: "11/11/2024",
        description: "Wapenstilstand"
    },
    {
        date: "1/05/2025",
        description: "Dag Van De Arbeid"
    },
    {
        date: "2/05/2025",
        description: "Brugdag"
    },
    {
        date: "29/05/2025",
        description: "O.L. Heer Hemelvaart"
    },
    {
        date: "30/05/2025",
        description: "Brugdag"
    },
    {
        date: "10/11/2025",
        description: "Brugdag"
    },
    {
        date: "11/11/2025",
        description: "Wapenstilstand"
    },
  ];

  export const vacations = [
    {
        dates: [
            {
                from: "23/12/2024",
                until: "03/01/2025"
            }
        ],
        description: "Kerstvakantie"
    },
    {
        dates: [
            {
                from: "14/04/2025",
                until: "18/04/2025"
            }
        ],
        description: "Paasvakantie"
    },
    {
        dates: [
            {
                from: "21/07/2025",
                until: "01/08/2025"
            },
            {
                from: "11/08/2025",
                until: "15/08/2025"
            }
        ],
        description: "Zomervakantie"
    },
    {
        dates: [
            {
                from: "22/12/2025",
                until: "02/01/2026"
            }
        ],
        description: "Kerstvakantie"
    },
  ];


  