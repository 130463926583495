// import { Box, Container, Divider, Typography } from '@mui/material'
import { useState } from "react";
import { Gallery } from "react-grid-gallery";
// import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { images } from "../media/images";
import { Box, Container, Typography } from "@mui/material";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

const Media = () => {
  const [index, setIndex] = useState(-1);

  // const currentImage = images[index];
  // const nextIndex = (index + 1) % images.length;
  // const nextImage = images[nextIndex] || currentImage;
  // const prevIndex = (index + images.length - 1) % images.length;
  // const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index, item) => setIndex(index);
  const handleClose = () => setIndex(-1);
  // const handleMovePrev = () => setIndex(prevIndex);
  // const handleMoveNext = () => setIndex(nextIndex);

  return (
    <div>
    <Container maxWidth="xl" sx={{ textAlign: "center", backgroundColor: '#ffffffaa', position: 'sticky', top: '65px'}}>
    <Box sx={{pt:5, m: "auto"}}>
      <Typography variant="h1" sx={{fontSize: 50}}>
        Foto's
      </Typography>
    </Box>
    <Box sx={{paddingY: 5, paddingX: 10}}>

      <Lightbox
        slides={images}
        open={index >= 0}
        index={index}
        close={handleClose}
        // enable optional lightbox plugins
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
      />
      <Gallery
        images={images}
        onClick={handleClick}
        enableImageSelection={false}
        rowHeight={220}
      />
      {images.length == 0 && <p style={{fontSize: 24, color: '#884444'}}>Geen foto's om weer te geven.</p>}
    </Box>
      </Container>
      {/* {!!currentImage && (
        <Lightbox
          mainSrc={currentImage.original}
          imageTitle={currentImage.caption}
          nextSrc={nextImage.original}
          prevSrc={prevImage.original}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )} */}
    </div>
  );
}

export default Media