import React, { useState, useMemo } from 'react'
import { Box, Container, Typography } from '@mui/material'
import { vacations, days, settings } from '../openinghours/dates'
import { parse, format, isAfter, endOfToday } from 'date-fns'
import { nl } from 'date-fns/locale';

const isFutureDate = (dateString) => {
  const date = parse(dateString, 'dd/MM/yyyy', new Date());
  return isAfter(date, endOfToday());
};

const twoDayNames = {
  'maandag': 'ma',
  'dinsdag': 'di',
  'woensdag': 'wo',
  'donderdag': 'do',
  'vrijdag': 'vr',
  'zaterdag': 'za',
  'zondag': 'zo'
};

const OpeningHours = () => {
  const [groupByYear, setGroupByYear] = useState(settings.groupByYear);
  const [showDayNames, setShowDayNames] = useState(settings.showDayNames);

  const formatDate = (dateString, includeYear = false) => {
    const date = parse(dateString, 'dd/MM/yyyy', new Date());
    let formatString = 'dd/MM';
    if (includeYear || !groupByYear) formatString += '/yyyy';
    if (showDayNames) {
      const fullDayName = format(date, 'EEEE', { locale: nl }).toLowerCase();
      const dayName = twoDayNames[fullDayName];
      return `${dayName} ${format(date, formatString)}`;
    }
    return format(date, formatString);
  };

  const filteredData = useMemo(() => {
    const filteredVacations = vacations.map(vacation => ({
      ...vacation,
      dates: vacation.dates.filter(date => isFutureDate(date.from) || isFutureDate(date.until))
    })).filter(vacation => vacation.dates.length > 0);

    const filteredDays = days.filter(day => isFutureDate(day.date));

    return { vacations: filteredVacations, days: filteredDays };
  }, []);

  const groupedData = useMemo(() => {
    if (!groupByYear) {
      return {
        all: {
          vacations: filteredData.vacations,
          days: filteredData.days
        }
      };
    }

    return filteredData.vacations.concat(filteredData.days.map(day => ({
      description: day.description,
      dates: [{ from: day.date, until: day.date }]
    }))).reduce((acc, item) => {
      const years = new Set(item.dates.flatMap(date => {
        const fromYear = parse(date.from, 'dd/MM/yyyy', new Date()).getFullYear();
        const untilYear = parse(date.until, 'dd/MM/yyyy', new Date()).getFullYear();
        return Array.from({length: untilYear - fromYear + 1}, (_, i) => fromYear + i);
      }));

      years.forEach(year => {
        if (!acc[year]) acc[year] = { vacations: [], days: [] };
        if (item.dates.length === 1 && item.dates[0].from === item.dates[0].until) {
          acc[year].days.push({
            description: item.description,
            date: item.dates[0].from
          });
        } else {
          // Modify vacation dates that span across years
          const modifiedDates = item.dates.map(date => {
            const fromDate = parse(date.from, 'dd/MM/yyyy', new Date());
            const untilDate = parse(date.until, 'dd/MM/yyyy', new Date());
            const fromYear = fromDate.getFullYear();
            const untilYear = untilDate.getFullYear();

            if (fromYear !== untilYear) {
              if (year === fromYear) {
                return { ...date, until: '31/12/' + fromYear };
              } else if (year === untilYear) {
                return { ...date, from: '01/01/' + untilYear };
              }
            }
            return date;
          });

          acc[year].vacations.push({
            ...item,
            dates: modifiedDates
          });
        }
      });
      return acc;
    }, {});
  }, [groupByYear, filteredData]);

  const renderVacations = (vacations) => {
    return vacations.map((vacation, index) => (
      <tr key={`vacation-${index}`}>
        <th style={{ paddingRight: 10, verticalAlign: 'top' }}>
          {vacation.description}
        </th>
        <td>
          {vacation.dates.map((date, j) => (
            <React.Fragment key={j}>
              {j > 0 && <br />}
              {formatDate(date.from, !groupByYear)} - {formatDate(date.until, !groupByYear)}
            </React.Fragment>
          ))}
        </td>
      </tr>
    ));
  };

  const renderDays = (days) => {
    return days.map((day, index) => (
      <tr key={`${day.description}-${index}`}>
        <th style={{ paddingRight: 10 }}>
          {day.description}
        </th>
        <td>
          {formatDate(day.date, !groupByYear)}
        </td>
      </tr>
    ));
  };

  const renderVacationsAndDays = (data) => (
    <>
      {data.vacations.length > 0 && renderVacations(data.vacations)}
      {data.vacations.length > 0 && data.days.length > 0 && (
        <tr><td colSpan="2"><br /></td></tr>
      )}
      {data.days.length > 0 && renderDays(data.days)}
    </>
  );

  return (
    <Container maxWidth="xl" sx={{ textAlign: "center", backgroundColor: '#ffffffaa' }}>
      {/* <FormControlLabel
        control={<Switch checked={groupByYear} onChange={(e) => setGroupByYear(e.target.checked)} />}
        label="Group by Year"
      />
      <FormControlLabel
        control={<Switch checked={showDayNames} onChange={(e) => setShowDayNames(e.target.checked)} />}
        label="Show Day Names"
      /> */}
      <Box sx={{ pt: 5, m: "auto" }}>
        <Box id="openinghours-tables">
          <div id='openinghours'>
            <table id='openinghours-table'>
              <tbody>
                <tr>
                  <th className='title' colSpan="2">
                    <Typography variant='h1' sx={{ fontSize: '30px', marginBottom: 2 }}>Openingsuren</Typography>
                  </th>
                </tr>
                <tr>
                  <th style={{ paddingRight: 10 }}>maandag</th>
                  <td>
                    7u00 - 18u00
                  </td>
                </tr>
                <tr>
                  <th style={{ paddingRight: 10 }}>dinsdag</th>
                  <td>
                    7u00 - 18u00
                  </td>
                </tr>
                <tr>
                  <th style={{ paddingRight: 10 }}>woensdag</th>
                  <td>
                    7u00 - 18u00
                  </td>
                </tr>
                <tr>
                  <th style={{ paddingRight: 10 }}>donderdag</th>
                  <td>
                    7u00 - 18u00
                  </td>
                </tr>
                <tr>
                  <th style={{ paddingRight: 10 }}>vrijdag</th>
                  <td>
                    7u00 - 18u00
                  </td>
                </tr>
                <tr>
                  <th style={{ paddingRight: 10 }}>zaterdag</th>
                  <td className='closed'>
                    Gesloten
                  </td>
                </tr>
                <tr>
                  <th style={{ paddingRight: 10 }}>zondag &<br />feestdagen</th>
                  <td className='closed'>
                    Gesloten
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div id='closingDays'>
            {Object.entries(groupedData).map(([year, data]) => (
              <table key={year} id='closingdays-table'>
                <tbody>
                  <tr>
                    <th className='title' colSpan="2">
                      <Typography variant='h1' sx={{ fontSize: '30px', marginBottom: 2 }}>
                        {groupByYear ? `Sluitingsdagen ${year}` : "Sluitingsdagen"}
                      </Typography>
                    </th>
                  </tr>
                  {renderVacationsAndDays(data)}
                </tbody>
              </table>
            ))}
          </div>
        </Box>
      </Box>
    </Container>
  );
};

export default OpeningHours;